import {useState} from "react";
import HighlightedButton from "../common/HighlightedButton";
import CustomInputForm from "../common/CustomInputForm";
import HighlightedCheckedButton from "../common/HighlightedCheckedButton";

const UserPropertiesAdmin = ({user, handleSubmit, handleReset, handleDelete, handleAdmin}) => {
    const [username, setUsername] = useState<string>(user.username);
    const [firstName, setFirstName] = useState<string>(user.firstName);
    const [lastName, setLastName] = useState<string>(user.lastName);
    const [email, setEmail] = useState<string>(user.email);
    const [wallet] = useState<number>(user.wallet?.balance);

    const update = () => {
        user.username = username;
        user.firstName = firstName;
        user.lastName = lastName;
        user.email = email;
        handleSubmit(user);
    }


    return (
        <div className={"admin-form"}>
            <form>
                <CustomInputForm type={"text"} value={username} placeholder={"Username"} name={"username"} onChange={e => setUsername(e.target.value)}/>
                <CustomInputForm type={"text"} value={firstName} placeholder={"Voornaam"} name={"firstName"} onChange={e => setFirstName(e.target.value)}/>
                <CustomInputForm type={"text"} value={lastName} placeholder={"Achternaam"} name={"lastName"} onChange={e => setLastName(e.target.value)}/>
                <CustomInputForm type={"text"} value={email} placeholder={"Email"} name={"email"} onChange={e => setEmail(e.target.value)}/>
                <div className={"admin-user-bottom"}>
                    {isNaN(wallet) ? null : <div>Balance: {(wallet / 100).toFixed(2)}</div>}
                    {!user.userId ? null :
                        (user.userRoles.filter(ur => ur === "ADMIN").length) > 0
                            ? <HighlightedCheckedButton text="Verwijder admin" onClick={() => handleAdmin(user, false)} checked={false}/>
                            : <HighlightedCheckedButton text="Maak admin" onClick={() => handleAdmin(user, true)} checked={true}/>}

                </div>

                <div>
                    <div className={"update-button"}>
                        <HighlightedButton text={!user.userId ? "Creëer" : "Update"} onClick={update}/>
                    </div>
                    {!user.userId ? null : <HighlightedButton text="Reset wachtwoord" onClick={() => handleReset(user)}/>}
                    {!user.userId ? null : <div className={"form-bottom"}>
                        <img onClick={() => handleDelete(user)} className={"form-bottom-image"} src="icons/delete-icon.png" alt="delete"/>
                    </div>}
                </div>
            </form>
        </div>
    )
}

export default UserPropertiesAdmin