import {ButtonBase} from "@material-ui/core";
import React, {ReactElement} from "react";
import styled from "styled-components";

type tButtonProps = {
    text: string | ReactElement;
    variant?: string;
    onClick?: () => void;
    checked: boolean;
};

const StyledButton = styled(ButtonBase)`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  font-size: 14px;
  && {
    padding: 5px 20px;
    color: white;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.secondaryLight};
    color: black;
  }
`;

const StyledCheckedButton = styled(ButtonBase)`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  font-size: 14px;
  && {
    background-color: ${(props) => props.theme.colors.secondary};
    padding: 5px 20px;
    color: black;
  }
`;

const HighlightedButton = ({text, variant, onClick, checked}: tButtonProps) => {
    return checked
        ? <StyledCheckedButton className={"checked"} onClick={onClick ? onClick : () => null}>{text}</StyledCheckedButton>
        : <StyledButton className={"not-checked"} onClick={onClick ? onClick : () => null}>{text}</StyledButton>
};

export default HighlightedButton;
