import "../../css/OrderPage.css";
import React, {useEffect, useState} from "react";
import HighlightedButton from "../common/HighlightedButton";
import {equalsBagItem, equalsDish, IBag, IDish, IDishType} from "../Types";
import {useAPI} from "../../utilities/useAPI";
import Dish from "../common/order/Dish";
import {NotificationManager} from 'react-notifications';
import LoadIndicator from "../common/LoadIndicator";

const OrderPage = () => {
    const {state, doFetch} = useAPI();
    const [dishes, setDishes] = useState<IDish[]>([]);
    const [types, setTypes] = useState<IDishType[]>([]);
    const [selector, setSelector] = useState<string>("ALLES");
    const [balance, setBalance] = useState<number>(0);
    const [bag, setBag] = useState<IBag>({
        bagItems: [],
        bagTotal: 0,
        foodTotal: 0,
        drinkTotal: 0,
        priority: false
    })

    useEffect(() => {
        //TODO combine three calls into one call.
        doFetch<IDish[]>("/api/dish/visible").then((res) => {
            setDishes(prev => prev.concat(res));
        });
        doFetch<IDishType[]>("/api/dish/types").then((res) => {
            res.unshift({key: "ALLES", description: "Alles", itemState: "ACTIVE"});
            setTypes(res);
        });
        getBalance();
        return () => {
            if (state.isLoading) {
                state.controller.abort();
            }
            setDishes([]);
            setBag({
                bagItems: [],
                bagTotal: 0,
                foodTotal: 0,
                drinkTotal: 0,
                priority: false
            })
        };
        // eslint-disable-next-line
    }, []);

    const getBalance = () => {
        doFetch<number>("/api/user/balance").then((res) => {
            setBalance(res);
        });
    }

    const handleSubmit = () => {
        if (bag.bagItems.length === 0) {
            NotificationManager.info('Lege bestellingen zijn niet toegelaten.');
            return;
        }
        doFetch<IBag[]>("/api/order/create", "POST", JSON.stringify(bag), {
            "Content-Type": "application/json",
        }).then(() => {
            getBalance()
            setBag({bagItems: [], bagTotal: 0, drinkTotal: 0, foodTotal: 0, priority: false});
        });
    }

    const handleAdd = (dish, quantity, remark) => {
        let newBagItems = [...bag.bagItems];
        const newBagItem = {dish: dish, quantity: quantity, remark: remark};
        const filtered = bag.bagItems.filter(bagitem => equalsBagItem(bagitem, newBagItem));
        if (filtered.length === 0) {
            if (newBagItem.quantity < 0)
                newBagItem.quantity = 0;
            newBagItems.push(newBagItem);
        } else {
            newBagItem.quantity += filtered[0].quantity;
            if (newBagItem.quantity < 0)
                newBagItem.quantity = 0;
            newBagItems = bag.bagItems.filter(bagitem => !equalsBagItem(bagitem, newBagItem));
            newBagItems.push(newBagItem);
        }
        newBagItems = newBagItems.filter(bagItem => bagItem.quantity > 0); //filter items out that have quantity on 0
        setBag({
            priority: false,
            bagItems: newBagItems,
            bagTotal: calculateBagTotal(newBagItems),
            foodTotal: calculateFoodTotal(newBagItems),
            drinkTotal: calculateDrinkTotal(newBagItems)
        })
    }

    const calculateBagTotal = (bagItems) => {
        return bagItems.map(item => (item.dish.price) * item.quantity).reduce((x, y) => x + y, 0);
    }

    const calculateFoodTotal = (bagItems) => {
        return bagItems.filter(item => item.dish.foodType.key === "FOOD").map(item => (item.dish.price) * item.quantity).reduce((x, y) => x + y, 0);
    }

    const calculateDrinkTotal = (bagItems) => {
        return bagItems.filter(item => item.dish.foodType.key !== "FOOD").map(item => (item.dish.price) * item.quantity).reduce((x, y) => x + y, 0);
    }

    const getDishes = () => {
        try {
            if (selector === "ALLES") {
                return dishes.filter(d => types.map(type => type.key).indexOf(d.foodType.key) > 0);
            }
            return dishes.filter(d => d.foodType.key === selector && types.map(type => type.key).indexOf(d.foodType.key) > 0);

        } catch (e) {

        }
        return [];
    }

    const setType = (key) => {
        setSelector(key);
    }

    const getBagTotalOfDish = (dish) => {
        return bag.bagItems.filter(bagItem => equalsDish(bagItem.dish, dish)).map(item => item.quantity).reduce((x, y) => x + y, 0);
    }

    const getPredictedTotal = () => {
        if (balance - bag.bagTotal < 0)
            return (balance - (bag.bagTotal * 1.1)) / 100
        else
            return (balance - bag.bagTotal) / 100;
    }

    return (
        state.shouldShowLoading
            ? <LoadIndicator/>
            : <div className={"parent-order"}>
                <div className={"scrolling-wrapper"}>

                    {types.map((type, i) =>
                        // eslint-disable-next-line
                        <a key={i} onClick={() => setType(type.key)}>{type.description}</a>
                    )}
                </div>
                <div className={"order-header"}>
                    {bag.bagItems.length > 0
                        ? <div className={"moneyz-active"}>Saldo: €{getPredictedTotal().toFixed(2)}</div>
                        : <div className={"moneyz"}>Saldo: €{(balance / 100).toFixed(2)}</div>
                    }
                    <HighlightedButton text={"Geef aantal door"} onClick={handleSubmit}/>
                </div>
                <div className={"dish-collection"}>
                    {getDishes().map((dish, i) => {
                        return (
                            <Dish key={i} dish={dish} count={getBagTotalOfDish(dish)} handleAdd={handleAdd}/>
                        );
                    })}
                </div>
            </div>
    )
};

export default OrderPage;
