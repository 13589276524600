import React, {useState} from "react";
import DestinationSelectorItem from "../common/DestinationSelectorItem";
import "css/Stats.css"
import TransactionsAdmin from "./TransactionsAdmin";

const StatsAdmin = () => {
    const [selected, setSelected] = useState<string>("Transacties");
    const selectOptions = ["Dag gebruik","Maand gebruik", "Transacties"];

    const selector = () => {
        switch (selected) {
            case "Opdieners":
                return null;
                //return <StatsUser/>;
            case "Bestellingen":
                return null;
                //return <StatsOrder/>;
            case "Transacties":
                return <TransactionsAdmin/>;
            default:
                return <div>todo</div>;;
        }
    }

    return (
        <div className={"admin-child"}>
            <div className={"selector-parent"}>
                <div className={"selector-title"}/>
                <div className={"selector"}>
                    {selectOptions.map((page, i) => (
                        <DestinationSelectorItem key={i} to={"admin"}
                                                 onClick={() => setSelected(page)}
                                                 text={page}
                                                 link={""}
                                                 isActive={page === selected}/>
                    ))}
                </div>
            </div>
            {selector()}
        </div>
    );
};

export default StatsAdmin;