export const USERNAME_REGEX = "^[A-Za-z]\\w{3,29}$";

export interface IDish {
    dishId: string;
    name: string;
    price: number;
    positionOrder: number;
    foodType: IDishType;
    description: string;
    itemState: string;
}

export const equalsDish = (dish1: IDish, dish2: IDish) => {
    return dish1.dishId === dish2.dishId && dish1.price === dish2.price && dish1.name === dish2.name && dish1.foodType === dish2.foodType;
}

export interface IBagItem {
    dish: IDish;
    quantity: number;
    remark: string;
}

export const equalsBagItem = (bagItem1: IBagItem, bagItem2: IBagItem) => {
    return bagItem1.dish.dishId === bagItem2.dish.dishId && bagItem1.remark === bagItem2.remark;
}

export interface IBag {
    bagItems: IBagItem[];
    bagTotal: number;
    foodTotal: number;
    drinkTotal: number;
    priority: boolean;
}

export interface IDishType {
    key: string;
    description: string;
    itemState: string;
}

export interface IOrderItem {
    itemId: string;
    name: string;
    quantity: number;
    remark: string;
}

export interface IOrder {
    orderID: string;
    timestamp: string;
    instructions: string;
    transaction: ITransaction;
    items: IOrderItem[];
    orderState: string;
    priority: boolean;
}

export interface ITransaction {
    transactionId: string;
    transactionType: string;
    timestamp: string;
    value: number;
    executor: string;
    wallet: IWallet;
    order?: IOrder;
}

export interface IWallet {
    walletId: string;
    balance: number;
}

export interface ITopUp {
    userId: string;
    topUp: number;
}

export interface IUser {
    userId: string
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    printDestination: IPrinterDestination | null;
    password: string;
    itemState: string;
    userRoles: string[];
    wallet?: IWallet;
}

export interface IPrinterDestination {
    printDestinationId: string;
    name: string;
    description: string;
    serverAddress: string;
    path: string;
    printerName: string;
}

//STATISTIC OBJECTS
export interface IUserPointsStats {
    userName: string;
    userId: string;
    orderCount: number;
    orderMoneyTotal: number;
    orderSize: number;
}

//BARCHART
export interface IBarChart {
    label: string;
    alias: string;
    value: number;
}

//STOCK

export interface IStockDto {
    stockItems: IStockItem[];
    stockInterval: IStockInterval
}

export interface IStockInterval {
    nextInterval: number;
    intervalValue: number;
    increment: number;
}

export interface IDishData {
    dishId: string;
    totalSold: number;
}

export interface IStockItem {
    dish: IDish;
    enabled: boolean;
    histories: object[]; //todo
    id: string;
    stockPrice: IStockPrice;
    stockState: IStockState;
    stockOrder: number;
    updated: boolean;
}

interface IStockPrice {
    id: string;
    current: number;
    prevState: number; //simple history, only used in react to determine prev state
    max: number;
    min: number;
    original: number;
}

export enum IStockState {
    UP = ("UP"), DOWN = ("DOWN"), NEUTRAL = ("NEUTRAL")
}