import React, {useState} from "react";
import "css/ProfilePage.css";
import "css/AdminPage.css";
import AdminTopBar from "../admin/AdminTopBar";
import MenuAdmin from "../admin/MenuAdmin";
import UserAdmin from "../admin/UserAdmin";
import StatsAdmin from "../admin/StatsAdmin";
import BalanceAdmin from "../admin/BalanceAdmin";

const AdminPage = () => {
    const [page, setPage] = useState<string>("Menu");
    const adminPages = ["Menu", "Gebruikers", "Geld opladen", "Statistieken"]

    const selector = () => {
        switch (page) {
            case "Menu":
                return <MenuAdmin/>;
            case "Gebruikers":
                return <UserAdmin/>;
            case "Geld opladen":
                return <BalanceAdmin/>;
            case "Statistieken":
                return <StatsAdmin/>;
            default:
                return null;
        }
    }

    return (
        <div className="pageHeader order">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            <AdminTopBar toggle={setPage} adminPages={adminPages}/>
            <div className={"admin-panel"}>
                {selector()}
            </div>
        </div>
    );
}

export default AdminPage;
