import {useState} from "react";
import DestinationSelectorItem from "../common/DestinationSelectorItem";

const AdminTopBar = ({toggle, adminPages}) => {
    const [activeSelection, setActiveSelection] = useState<string>("Menu");


    const toggleSelection = (item) => {
        setActiveSelection(item)
        toggle(item)
    }

    return (
        <div className={"selector-parent"}>
            <div className={"order-title"}>ADMIN</div>
            <div className={"selector"}>
                {adminPages.map((page, i) => (
                    <DestinationSelectorItem key={i} to={"admin"}
                                             onClick={() => toggleSelection(page)}
                                             text={page}
                                             link={""}
                                             isActive={page === activeSelection}/>
                ))}
            </div>
        </div>
    );
};

export default AdminTopBar;