import React, {useEffect, useState} from "react";
import {useAPI} from "utilities/useAPI";
import "css/SettingsPage.css"
import $ from "jquery";
import {IUser, USERNAME_REGEX} from "../Types";

import SettingsTopBar from "../common/SettingsTopBar";
import HighlightedButton from "../common/HighlightedButton";
import {NotificationManager} from 'react-notifications';
import CustomInputForm from "../common/CustomInputForm";
import LoadIndicator from "../common/LoadIndicator";

const SettingsPage = (props) => {
    const {state, doFetch} = useAPI();
    const [username, setUsername] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [oldPassword, setOldPassword] = useState<string>();
    const [newPassword1, setNewPassword1] = useState<string>();
    const [newPassword2, setNewPassword2] = useState<string>();

    useEffect(() => {
        $("body").attr({login: false});
        doFetch<IUser>("/api/user").then((res) => {
            setFirstName(res.firstName);
            setLastName(res.lastName);
            setUsername(res.username);
            setEmail(res.email);
        });

        return () => {
            if (state.isLoading) {
                state.controller.abort();
            }
        };
        // eslint-disable-next-line
    }, []);

    const handleSubmit = () => {
        if (username === undefined || username === "" || username.length < 1) {
            NotificationManager.error("Gebruikersnaam mag niet leeg zijn!")
            return;
        }
        if (firstName === undefined || firstName === "" || firstName.length < 1) {
            NotificationManager.error("Voornaam mag niet leeg zijn!")
            return;
        }
        if (lastName === undefined || lastName === "" || lastName.length < 1) {
            NotificationManager.error("Achternaam mag niet leeg zijn!")
            return;
        }
        if (email === undefined || email === "" || email.length < 1) {
            NotificationManager.error("Email mag niet leeg zijn!")
            return;
        }

        setUsername(username.trim());
        setFirstName(firstName.trim());
        setEmail(email.trim());
        setLastName(lastName.trim());

        if (username.length < 4 || username.length > 28) {
            NotificationManager.error("Gebruikersnaam moet tussen 4 en 28 karakters lang zijn.")
            return;
        }

        if (!username.match(USERNAME_REGEX)) {
            NotificationManager.error("Gebruikersnaam bevat ongeldige tekens")
            return;
        }

        const user = {username: username, firstName: firstName, lastName: lastName, email: email};
        doFetch<IUser[]>("/api/user/update", "POST", JSON.stringify(user), {
            "Content-Type": "application/json",
        }).then(() => NotificationManager.success('Gegevens werden succesvol gewijzigd!')).catch((error) => console.error(error));
    }

    const handleSubmitPass = () => {
        if (newPassword1 !== newPassword2) {
            NotificationManager.error('Nieuwe wachtwoorden komen niet overeen!')
            return;
        }
        doFetch<boolean>("/api/user/password/correct", "POST", oldPassword, {
            "Content-Type": "application/json",
        }).then((res) => {
            if (!res) {
                NotificationManager.error('Oud wachtwoord klopt niet!')
            } else {
                doFetch<boolean>("/api/user/password/update", "POST", newPassword1, {
                    "Content-Type": "application/json",
                }).then((res) => {
                    if (res) {
                        NotificationManager.success('Wachtwoord werd succesvol gewijzigd!')
                        setOldPassword("");
                        setNewPassword1("");
                        setNewPassword2("")
                    } else {
                        NotificationManager.error('Er ging iets mis, contacteer een admin!')
                    }
                }).catch((error) => console.error(error));
            }
        }).catch((error) => console.error(error));
    }

    return (
        <div className="pageHeader order">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            <SettingsTopBar/>
            {state.shouldShowLoading ? <LoadIndicator/> :
                <div>
                    <div className={"profile-form"}>
                        <form onSubmit={handleSubmit}>
                            <CustomInputForm type={"text"} value={username} placeholder={"Username"} name={"username"} onChange={e => setUsername(e.target.value)} key={0}/>
                            <CustomInputForm type={"text"} value={firstName} placeholder={"Voornaam"} name={"firstName"} onChange={e => setFirstName(e.target.value)} key={1}/>
                            <CustomInputForm type={"text"} value={lastName} placeholder={"Achternaam"} name={"lastName"} onChange={e => setLastName(e.target.value)} key={2}/>
                            <CustomInputForm type={"text"} value={email} placeholder={"Email"} name={"email"} onChange={e => setEmail(e.target.value)} key={3}/>
                            <div className={"profile-button"}>
                                <HighlightedButton text="Update profiel" onClick={handleSubmit}/>
                            </div>
                        </form>
                    </div>
                    <div className={"profile-form"}>
                        <form onSubmit={handleSubmitPass}>
                            <CustomInputForm key={4} type={"password"} value={oldPassword} placeholder={"Oud wachtwoord"} name={"email"}
                                             onChange={e => setOldPassword(e.target.value)}/>
                            <CustomInputForm key={5} type={"password"} value={newPassword1} placeholder={"Nieuw wachtwoord"} name={"email"}
                                             onChange={e => setNewPassword1(e.target.value)}/>
                            <CustomInputForm key={6} type={"password"} value={newPassword2} placeholder={"Herhaal nieuw wachtwoord"} name={"email"}
                                             onChange={e => setNewPassword2(e.target.value)}/>
                            <div className={"profile-button"}>
                                <HighlightedButton text="Update wachtwoord" onClick={handleSubmitPass}/>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </div>
    );
};

export default SettingsPage;
