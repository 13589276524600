import {useAccessToken} from "./AccessTokenProvider";

const useToken = () => {
    const [accessToken] = useAccessToken();

    const parseJwt = () => {
        try {
            if (!accessToken) throw new Error("No token available.");
            return JSON.parse(atob(accessToken.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const getName = () => {
        const payload = parseJwt();
        return payload.firstname + " " + payload.lastname;
    };

    const getId = () => {
        const payload = parseJwt();
        return payload.sub;
    };

    const getRoles = () => {
        const payload = parseJwt();
        return payload.roles;
    };

    const isAdmin = () => {
        const payload = parseJwt();
        let roles: String = payload.roles;
        let arrayRoles = roles.substring(1, roles.length - 1).split(",");

        return arrayRoles.some((role) => role === "ADMIN");
    };

    return {getName, getId, getRoles, isAdmin};
};

export default useToken;
