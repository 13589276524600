import React, {useState} from "react";
import Type from "./Type";
import "../../css/AdminDish.css"
import {IDish, IDishType} from "../Types";
import {useAPI} from "../../utilities/useAPI";
import CustomInputForm from "../common/CustomInputForm";

const AdminDish = ({
                       types,
                       dish,
                       setUp,
                       deleteDish,
                       setVisible,
                       showOptions,
                       setShowOptions
                   }) => {
    const [type, setType] = useState<IDishType>(dish.foodType);
    // eslint-disable-next-line
    const [name, setName] = useState<string>(dish.name);
    // eslint-disable-next-line
    const [price, setPrice] = useState<number>(dish.price);
    const {doFetch} = useAPI();

    const handleProp = (e, f, ...args) => {
        e.stopPropagation();
        f(args);
    }

    const handleName = (value) => {
        dish.name = value;
        updateDish(dish);
    }

    const handlePrice = (value) => {
        dish.price = value;
        updateDish(dish);
    }

    const handleType = (type) => {
        dish.foodType = type;
        updateDish(dish);
    }

    const updateDish = (dish) => {
        doFetch<IDish>("/api/dish/update", "POST", JSON.stringify(dish), {
            "Content-Type": "application/json",
        }).then((res) => {
            setType(res.foodType);
            setName(res.name);
            setPrice(res.price);
        });
    }

    return (
        <div className={"dish"}>
            <div className={"dish-item"} onClick={() => setShowOptions(!showOptions)}>
                <div className={"dish-icon"}>
                    {dish.foodType.key !== null
                        ? <img src={`icons/${dish.foodType.key.toLowerCase()}-icon.png`}
                               alt={dish.foodType.key.toLowerCase()}/>
                        : null}
                </div>
                <div className={"dish-name"}>
                    <CustomInputForm type={"text"} placeholder={"Naam"} name={"name"} value={dish.name}
                                     onClick={e => e.stopPropagation()}
                                     onChange={e => handleName(e.target.value)}/>
                </div>
                <div className={"dish-price"}>
                    <CustomInputForm type={"number"} placeholder={"Prijs"} name={"price"} value={dish.price}
                                     onClick={e => e.stopPropagation()}
                                     onChange={e => handlePrice(e.target.value)}/>
                </div>
                <div className={"buttons"}>
                    <img onClick={e => handleProp(e, setUp, true)} className={"icon"} src="icons/arrow-up.png"
                         alt="up"/>
                    <img onClick={e => handleProp(e, setVisible)} className={"icon"}
                         src={dish.itemState === "ACTIVE" ? "icons/eye-yellow.png" : "icons/eye-white.png"}
                         alt="visible"/>
                    <img onClick={e => handleProp(e, deleteDish)} className={"icon"} src="icons/delete-icon.png"
                         alt="delete"/>
                    <img onClick={e => handleProp(e, setUp, false)} className={"icon"} src="icons/arrow-down.png"
                         alt="down"/>
                </div>
            </div>
            {showOptions ?
                <div className={"dish-toggle-item"}>
                    <div className={"dish-options"}>
                        {types.map((t, i) =>
                            <Type key={i} type={t} checked={t.key === type.key}
                                  onCheck={checked => checked ? void (0) : handleType(t)}/>)}
                    </div>
                </div>
                : null}
        </div>
    );
};

export default AdminDish;