import {ButtonBase} from "@material-ui/core";
import React, {ReactElement} from "react";
import styled from "styled-components";

type tButtonProps = {
    text: string | ReactElement;
    variant?: string;
    onClick?: () => void;
};

const StyledButton = styled(ButtonBase)`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 700;
  font-size: 14px;
  && {
    background-color: ${(props) => props.theme.colors.secondary};
    padding: 5px 20px;
    color: black;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.secondaryLight};
  }
`;

const HighlightedButton = ({text, variant, onClick}: tButtonProps) => {
    // Uiteindelijk eens login werkt: (isLoggedIn? -> profiellink anders Login-button)

    return <StyledButton onClick={onClick ? onClick : () => null}>{text}</StyledButton>;
};

export default HighlightedButton;
