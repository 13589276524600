import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

type tSelectorItemProps = {
    link: string;
    text: string;
    isActive?: boolean;
    onClick: Function;
    to: string;
};

type tStyledSelecterItemProps = {
    active?: string;
};

const StyledLink = styled(Link)<tStyledSelecterItemProps>`
    color: ${(props) => (!(props.active === "true") ? "white" : (props) => props.theme.colors.primaryLight)};
    text-decoration: none;
    padding-left: 5rem;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
         
    @media (max-width: 600px) {
        &:hover {
            color: ${(props) => props.theme.colors.primaryLight};
        }
    }
`;

const DestinationSelectorItem = ({link, text, isActive, onClick, to}: tSelectorItemProps) => {
    return (
        <StyledLink to={to} onClick={(e) => onClick(link, e)} active={isActive!.toString()}>
            {text}
        </StyledLink>
    );
};

export default DestinationSelectorItem;