const Type = ({type, checked, onCheck}) => {

    return (
        <div className={checked ? "type-selected" : "type"} onClick={() => onCheck()}>
            <img src={`icons/${type.key.toLowerCase()}-icon.png`}
                 alt={type.key.toLowerCase()}/>
        </div>
    );
};

export default Type;