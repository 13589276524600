import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

type tNavbarItemProps = {
    link: string;
    text: string;
    isActive?: boolean;
    onClick: Function;
};

type tStyledNavbarItemProps = {
    active?: string;
};

const StyledLink = styled(Link)<tStyledNavbarItemProps>`
  color: ${(props) => (!(props.active === "true") ? "white" : (props) => props.theme.colors.primaryLight)};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  &:hover {
    color: ${(props) => props.theme.colors.primaryLight};
  }
`;

const NavbarItem = ({link, text, isActive, onClick}: tNavbarItemProps) => {
    return (
        <StyledLink to={link} onClick={(e) => onClick(link, e)} active={isActive!.toString()}>
            {text}
        </StyledLink>
    );
};

export default NavbarItem;
