import React from "react";
import styled from "styled-components";
import {Box} from "@material-ui/core";

type tCustomLoadBarProps = {
    time: number;
    countdown: number;
};

type tStyledLoadBarProps = {
    width: boolean;
};

const StyledLinearProgress = styled(Box)<tStyledLoadBarProps>`
  && {
    width: ${({width}) => (width > 100 ? "100" : width)}%;
    margin: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 5px;
    background-color: ${({theme}) => theme.colors.secondary};
    transition: 1000ms;
    transition-timing-function: linear;
  }
`;

const LoadIndicatorBar = ({time, countdown}: tCustomLoadBarProps) => {
    //const [countDown, setCountDown] = useState<number>(time);

    /*
    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDown - 1);
            if (countDown <= 0) {
                setCountDown(time)
            }
        }, 1000);

        return () => {
            clearInterval(interval)
        };
        // eslint-disable-next-line
    }, [time, countDown]);*/

    return <StyledLinearProgress width={countdown / time * 100}/>;
};

export default LoadIndicatorBar;
