import React, {ReactElement, useEffect} from "react";
import styled from "styled-components";

type tMobileMenuProps = {
    windowHeight: number;
    navBarHeight: number;
    open: boolean;
    children: ReactElement;
};

const StyledMobileMenu = styled.div<tMobileMenuProps>`
  display: flex;
  flex-direction: ${(props) => (props.open ? "row" : "column")};
  justify-content: center;
  background-color: ${({theme}) => theme.colors.primary};
  height: calc(100vh - ${(props) => props.navBarHeight - 50}px);
  text-align: left;
  position: absolute;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  transform: ${(props) => (props.open ? "translateX(0)" : "translateX(-100%)")};

  @media (max-width: ${({theme}) => theme.breakpoints.md}px) {
    width: 100%;
    height: ${(props) => (props.windowHeight)}px;
  }

  a {
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: ${({theme}) => theme.breakpoints.md}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${({theme}) => theme.colors.primaryLight};
    }
    
    button {
      bottom: 1px;
      height: 45px;
      font-size: 1.6rem;
      width: 200px;
    }
  }
  .navList {
    top: calc(100% - 425px);
  }
`;

const MobileMenu = ({windowHeight, navBarHeight, open, children}: tMobileMenuProps) => {

    useEffect(() => {
        if(open){
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open]);

    return (
        <StyledMobileMenu open={open} navBarHeight={navBarHeight} windowHeight={windowHeight}>
            {children}
        </StyledMobileMenu>
    );
};

export default MobileMenu;
