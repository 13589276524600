import React from "react";
import styled from "styled-components";

type tMenuBurgerProps = {
    open: boolean;
    onClick: () => void;
};

const StyledBurger = styled.button<tMenuBurgerProps>`
  position: relative;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;

  &:focus {
    outline: none;
  }

  &:hover {
    div {
      background: ${({theme}) => theme.colors.primaryLight};
    }
  }

  div {
    width: 3rem;
    height: 0.2rem;
    background: white;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    margin: 2px 0;

    :first-child {
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${(props) => (props.open ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const MenuBurger = ({open, onClick}: tMenuBurgerProps) => {
    return (
        <StyledBurger className={"MenuBurger"} open={open} onClick={onClick}>
            <div/>
            <div/>
            <div/>
        </StyledBurger>
    );
};

export default MenuBurger;
