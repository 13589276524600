import React, {FormEvent, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import CustomAlert from "../common/CustomAlert";
import logo from "img/foodterminal-logo.png";
import $ from "jquery";

type tLoginProps = {
    logIn: (username: string, password: string) => Promise<void>;
    error: boolean;
};

const StyledForm = styled(Form)`
  width: 100%;

  .form-group .form-control,
  .btn {
    font-size: 1.3rem;
    height: 40px;
    border-radius: 0px;
    background-color: #ffffffc9;
    color: #2d2d2d;
    width: 100%;
  }
  
  input {
    padding-left: 10px;
  }

  .btn {
    background-color: ${({theme}) => theme.colors.secondary};
    color: black;
    text-transform: uppercase;
    border: none;

    &:hover,
    &:focus {
      background-color: ${({theme}) => theme.colors.secondaryLight};
    }

    &:focus {
      color: black;
      outline: none;
    }

    &[disabled] {
      opacity: 0.25;
    }
  }
`;

const Login = ({logIn, error}: tLoginProps) => {
    $("body").attr({login: true}); // Not very clean. Een container van className veranderen is ook wel niet zo enorm clean. Hoe zou ik dit het beste doen?

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        logIn(username, password);
    };

    const validateLogin = () => {
        return username.length > 0 && username.length < 1000 && password.length > 0 && password.length < 1000;
    };

    return (
        <div className="login-container">
            <img src={logo} alt="FoodTerminal logo"/>
            <StyledForm className="login-form" onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Control
                        size="lg"
                        type="text"
                        value={username}
                        placeholder="Gebruikersnaam"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Control
                        size="lg"
                        type="password"
                        value={password}
                        placeholder="Wachtwoord"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Button id="login-button" block type="submit" disabled={!validateLogin()}>
                    Log in
                </Button>
                {error ? <CustomAlert type="error" message="Fout bij het inloggen. Kijk je gegevens na." small={true}/> : ""}
            </StyledForm>
        </div>
    );
};

export default Login;
