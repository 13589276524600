import {useLayoutEffect, useState} from "react";

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);

    //TODO: Momenteel gebeurt een state update (en dus ook rerender van de componenten die de Hook gebruiken) bij elke resize. Best aanpassen om enkel te rerenderen bij breakpoints.
    const updateSize = () => {
        setSize([
            Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        ]);
    };

    useLayoutEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
};

export default useWindowSize;
