import React, {useState} from "react";
import MenuItemsAdmin from "./MenuItemsAdmin";
import DestinationSelectorItem from "../common/DestinationSelectorItem";

const MenuAdmin = () => {
    const [menuPage, setMenuPage] = useState<string>("Menu-items");
    const menuPages = ["Menu-items"]

    const selector = () => {
        switch (menuPage) {
            case "Menu-items":
                return <MenuItemsAdmin/>;
            case "Types":
                return null;
                //return <TypesAdmin/>;
            default:
                return null;
        }
    }

    return (
        <div className="menu-admin">
            <div className={"selector-parent"}>
                <div className={"order-title"}/>
                <div className={"selector"}>
                    {menuPages.map((page, i) => (
                        <DestinationSelectorItem key={i} to={"admin"}
                                                 onClick={() => setMenuPage(page)}
                                                 text={page}
                                                 link={""}
                                                 isActive={page === menuPage}/>
                    ))}
                </div>
            </div>
            {selector()}
        </div>
    );
};

export default MenuAdmin;