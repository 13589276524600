import React from "react";
import min from "img/min-icon.png";
import plus from "img/plus-icon.png";
import "../../../css/Dish.css";

const Dish = ({dish, count, handleAdd}) => {

    const handleAddWithPropagation = (e, quantity) => {
        if (e && e.stopPropagation) e.stopPropagation();
        handleAdd(dish, quantity, null);
    }

    return (
        <div className="dish-parent">
            <img className={count === 0 ? "dish-img hidden" : "dish-img"} src={min} alt="min" onClick={e => handleAddWithPropagation(e, -1)}/>
            <div className={"dish-content"}>
                <div className={"dish-25"}>€{(dish.price / 100).toFixed(2)}</div>
                <div className={"dish-center"}>{dish.name}</div>
                <div className={count === 0 ? "dish-75 hidden" : "dish-75"}>{count}</div>
            </div>
            <img className={"dish-img"} src={plus} alt="plus" onClick={e => handleAddWithPropagation(e, 1)}/>
        </div>
    );
};

export default Dish;
