import React from "react";

const SettingsTopBar = () => {

    return (
        <div className={"selector-parent"}>
            <div className={"order-title"}>INSTELLINGEN</div>
        </div>
    );
};

export default SettingsTopBar;