import {Input} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

type tInputProps = {
    type: string;
    value?: string | number;
    placeholder: string;
    name: string;
    onChange?: (e) => void;
    onClick?: (e) => void;
    onKeyDown?: (e) => void;
};

const StyledLabel = styled(Input)`
  && {
    height: 37px;
    position: relative;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    background-color: rgba(100, 100, 100, 0.4);
    z-index: 0;
  }

  input {
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    outline: none;
    color: white;
    box-shadow: 0 4px 20px 0 transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow
  }
`;

const CustomInputForm = ({type, value, placeholder, name, onChange, onClick, onKeyDown}: tInputProps) => {
    return <StyledLabel type={type} value={value} placeholder={placeholder} name={name} onChange={onChange} onClick={onClick} onKeyDown={onKeyDown}/>;

};

export default CustomInputForm;
