import {ReactElement} from "react";

type tConditionalWrapperProps = {
    condition: boolean;
    wrapper: (children: ReactElement) => JSX.Element;
    children: ReactElement;
};

const ConditionalWrapper = ({condition, wrapper, children}: tConditionalWrapperProps) =>
    condition ? wrapper(children) : children;

export default ConditionalWrapper;
