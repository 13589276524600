import React, {useState} from "react";
import "../../css/ResetPasswordModal.css";
import HighlightedButton from "./HighlightedButton";
import {useAPI} from "../../utilities/useAPI";
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


const ResetPasswordModal = ({resetPassword}) => {
    const {doFetch} = useAPI();
    const [newPassword1, setNewPassword1] = useState<string>();
    const [newPassword2, setNewPassword2] = useState<string>();

    const handleSubmit = () => {
        if (newPassword1 !== newPassword2) {
            NotificationManager.error('Wachtwoorden komen niet overeen');
            return;
        }

        doFetch<boolean>("/api/user/password/shouldUpdate", "POST", newPassword1, {
            "Content-Type": "application/json",
        }).then((res) => {
            if (res) {
                NotificationManager.success('Wachtwoord werd succesvol gewijzigd!', 'Wachtwoord wijzigen');
                setNewPassword1("");
                setNewPassword2("")
            } else {
                NotificationManager.error('Er ging iets mis, heb je toevallig jouw wachtwoord al aangepast? Contacteer eventueel een admin.');
            }
            resetPassword(false);
        }).catch(() => NotificationManager.error('Er ging iets mis. Contacteer een admin.'));
    }

    const handleCancel = () => {
        resetPassword(false);
    };

    return (
        <div className={"reset-modal"}>
            <div className={"reset-modal-content"}>
                <div className={"reset-modal-header"}>
                    Wachtwoord aanpassen.
                </div>
                <div className={"reset-modal-text"}>
                    Uit veiligheidsredenen raden we je aan jouw wachtwoord aan te passen omdat je nog gebruik maakt van het standaard wachtwoord. Dit kan je hieronder doen.
                </div>
                <div>
                    <form onSubmit={handleSubmit}>
                        <label className={"password-modal"}>
                            <input type="password" placeholder="Nieuw wachtwoord" value={newPassword1} onChange={e => setNewPassword1(e.target.value)}/>
                        </label>
                        <label className={"password-modal"}>
                            <input type="password" placeholder="Herhaal nieuw wachtwoord" value={newPassword2} onChange={e => setNewPassword2(e.target.value)}/>
                        </label>
                    </form>
                </div>
                <div className={"reset-buttons"}>
                    <HighlightedButton text={"Cancel"} onClick={handleCancel}/>
                    <HighlightedButton text={"Update"} onClick={handleSubmit}/>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordModal;
