import React, {useEffect, useState} from "react";
import NavbarItem from "./NavbarItem";
import "../../css/Navbar.css";
import HighlightedButton from "../common/HighlightedButton";
import MenuBurger from "./MenuBurger";
import useWindowSize from "utilities/useWindowSize";
import MobileMenu from "./MobileMenu";
import ConditionalWrapper from "utilities/ConditionalWrapper";
import {Link} from "react-router-dom";
import useToken from "../../utilities/useToken";
import logo from "img/foodterminal-logo.png";


type tNavbarProps = {
    onLogout: () => void;
};

const Navbar = ({onLogout}: tNavbarProps) => {
    const [activeLink, setActiveLink] = useState(window.location.pathname.split("/")[1]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [elementHeight, setElementHeight] = useState(0);
    const size = useWindowSize();
    const {getRoles} = useToken();

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClick = (clickedLink: string, e: MouseEvent) => {
        setActiveLink(clickedLink);
        if (menuOpen) handleMenuToggle();
    };

    type tMenuItemsInfo = {
        [key: string]: string;
    };

    const menuItemsInfo: tMenuItemsInfo = getRoles().includes("ADMIN")
        ? {
            admin: "Admin",
            stock: "Stock",
            "": "Menu",
            transactions: "Verrichtingen",
            settings: "Instellingen",
        }
        : {
            stock: "Stock",
            "": "Menu",
            transactions: "Verrichtingen",
            settings: "Instellingen",
        };

    useEffect(() => {
        setElementHeight(document.getElementsByClassName("navBarContent")[0].clientHeight);
    }, []);

    return (
        <div className="navBar">
            <div className="navBarContent">
                <div className={"navBarGroup"}>
                    <img className={"logo"} src={logo} alt="FoodTerminal Logo"/>
                    <div className={"logo-text"}>v{`${process.env.REACT_APP_VERSION}`}</div>
                </div>
                {/* Als de width van de Window klein genoeg is (Breakpoint), moet het Hamburgermenu getoond worden i.p.v. traditionele NavBar */}
                <ConditionalWrapper
                    condition={size[0] < 1024}
                    wrapper={(children) => (
                        <MobileMenu windowHeight={size[1]} navBarHeight={elementHeight} open={menuOpen}>
                            {children}
                        </MobileMenu>
                    )}
                >
                    <ul className="navList">
                        {Object.keys(menuItemsInfo).map((menuItem) => (
                            <li key={menuItem}>
                                <NavbarItem
                                    onClick={handleClick}
                                    link={menuItem}
                                    text={menuItemsInfo[menuItem]}
                                    isActive={menuItem === activeLink}
                                />
                            </li>
                        ))}
                        <li>
                            <Link to="/">
                                <HighlightedButton text="Log Uit" onClick={onLogout}/>
                            </Link>
                        </li>
                    </ul>
                </ConditionalWrapper>
                {size[0] < 1024 ? <MenuBurger open={menuOpen} onClick={handleMenuToggle}/> : null}
            </div>
        </div>
    );
};

export default Navbar;
