import {LinearProgress} from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledLinearProgress = styled(LinearProgress)`
  && {
    width: 33%;
    margin: auto;
    margin-top: 1rem;
  }

  && {
    background-color: ${({theme}) => theme.colors.secondary};
  }

  .MuiLinearProgress-root {
    background-color: ${({theme}) => theme.colors.secondary};
  }

  .MuiLinearProgress-bar {
    background-color: ${({theme}) => theme.colors.secondaryLight};
  }
`;

const LoadIndicator = () => {
    return <StyledLinearProgress/>;
};

export default LoadIndicator;
