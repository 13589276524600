import React, {useEffect, useState} from "react";
import {ITopUp, IUser} from "../Types";
import {useAPI} from "../../utilities/useAPI";
import CustomInputForm from "../common/CustomInputForm";
import {NotificationManager} from 'react-notifications';
import UserBalanceAdmin from "./UserBalanceAdmin";

const BalanceAdmin = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [search, setSearch] = useState<string>("");
    const {doFetch} = useAPI();

    const findAllUsers = () => {
        setUsers([]); // triggers a complete repaint, otherwise the children are not updated
        doFetch<IUser[]>("/api/user/all").then((res) => {
            setUsers(res);
        });
    }

    useEffect(() => {
        findAllUsers();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = (user, topUpValue) => {
        const payload: ITopUp = {
            userId: user.userId,
            topUp: parseInt(topUpValue)
        }
        if (payload.topUp === 0) {
            NotificationManager.warning("Geef een waarde in.")
            return;
        } else if (payload.topUp > 1000_00) {
            NotificationManager.warning("Bedrag is te groot. Gelieve een kleiner bedrag op te laden.")
            return;
        }

        doFetch<boolean>("/api/user/balance/topup", "PUT", JSON.stringify(payload), {
            "Content-Type": "application/json",
        }).then((res) => {
            findAllUsers()
            res
                ? NotificationManager.success("Gebruiker " + user.username + "'s balance is gewijzigd.")
                : NotificationManager.warning("Gebruiker " + user.username + "'s balance is niet gewijzigd!", "Er liep iets mis.")
        });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (search !== "") {
                doFetch<IUser[]>("/api/user/search", "POST", search, {
                    "Content-Type": "application/json",
                }).then((res) => {
                    setUsers([]);
                    setUsers(res);
                });
            } else {
                findAllUsers()
            }
        }
    }

    return (
        <div className={"admin-child"}>
            <CustomInputForm type={"text"} placeholder={"Zoek gebruiker(s)"} name={"search"} value={search} onChange={e => setSearch(e.target.value)}
                             onKeyDown={e => handleKeyDown(e)}/>
            {users.map((user, i) =>
                <UserBalanceAdmin key={i} user={user} handleSubmit={handleSubmit}/>
            )}
        </div>
    );
};

export default BalanceAdmin;