import React from "react";

import {useState} from "react";
import HighlightedButton from "../common/HighlightedButton";
import CustomInputForm from "../common/CustomInputForm";

const UserPropertiesAdmin = ({user, key, handleSubmit, }) => {
    const [balance] = useState<number>(user.wallet?.balance);
    const [topUp, setTopUp] = useState<number>(0);

    const update = () => {
        handleSubmit(user, topUp);
    }

    return (

        <div key={key} className={"admin-form"}>
            <form>
                <div className={"balance-user-admin"}>
                    <div>{user.username}</div>
                    {balance === undefined ? null : <div>Balance: {(balance / 100).toFixed(2)}</div>}
                </div>
                <CustomInputForm type={"number"} value={topUp} placeholder={"opladen"} name={"topUp"} onChange={e => setTopUp(e.target.value)}/>
                <HighlightedButton text={"Top up"} onClick={update}/>
            </form>
        </div>
    )
}

export default UserPropertiesAdmin