import React, {useEffect, useState} from "react";
import {useAPI} from "utilities/useAPI";
import "css/Stock.css"
import "react-pagination-js/dist/styles.css";
//import Ticker from "react-ticker";
import {IDishData, IStockDto, IStockInterval, IStockItem, IStockState} from "../Types";
import $ from "jquery";
import LoadIndicatorBar from "../common/LoadIndicatorBar";
import HighlightedButton from "../common/HighlightedButton";
import useToken from "../../utilities/useToken";

const DEFAULT_COUNTDOWN = 300;
const DEFAULT_INCREMENT = 25;

const OrderPage = (props) => {
    const [stock, setStock] = useState<IStockItem[]>([]);
    const [stockInterval, setStockInterval] = useState<IStockInterval>({nextInterval: DEFAULT_COUNTDOWN, intervalValue: DEFAULT_COUNTDOWN, increment: DEFAULT_INCREMENT});
    const [getDishData, setDishData] = useState<IDishData[]>([]);
    const [pageIsModified, setPageIsModified] = useState<boolean>(false);
    const [countDown, setCountDown] = useState<number>(DEFAULT_COUNTDOWN);
    const {doFetch} = useAPI();
    const {getRoles} = useToken();

    useEffect(() => {
        $("body").attr({login: false});

        if (stock.length === 0) getStockData();

        let interval = setInterval(() => {
            setCountDown(countDown - 1);
            if (countDown <= 0) {
                getStockData()
                setCountDown(stockInterval.intervalValue)
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [countDown, stock]);

    const getStockData = () => {
        setPageIsModified(false);

        doFetch<IStockDto>("/api/data/stock").then((res) => {
            res.stockItems.forEach(s => s.stockPrice.prevState = s.stockPrice.current);
            setStock(res.stockItems);
            setStockInterval(res.stockInterval);
            setCountDown(res.stockInterval.nextInterval)
        });
        doFetch<IDishData[]>("/api/data/dish").then((res) => {
            setDishData(res);
        });
    }

    const updateStock = () => {
        setPageIsModified(false);

        doFetch<IStockDto>("/api/data/stock/update", "POST", JSON.stringify(stock), {
            "Content-Type": "application/json"
        }).then((res) => {
                res.stockItems.forEach(s => s.stockPrice.prevState = s.stockPrice.current);
                setStock(res.stockItems);
                setStockInterval(res.stockInterval);
                setCountDown(res.stockInterval.nextInterval)

            }
        )
        doFetch<IDishData[]>("/api/data/dish", "GET").then((res) => {
            setDishData(res);
        });
    }

    const synchronize = () => {
        doFetch<IStockDto>("/api/data/stock/synchronize", "GET",).then((res) => {
                setStock(res.stockItems);
                setStockInterval(res.stockInterval);
                setCountDown(res.stockInterval.nextInterval)
                setAllArrowsNeutral();
            }
        )
        doFetch<IDishData[]>("/api/data/dish", "GET").then((res) => {
            setDishData(res);
        });
    }

    /*const pageIsModified = () => {
        return stock.filter(s => s.stockPrice.current !== s.stockPrice.prevState).length > 0;
    }*/

    const getStockArrow = (stockState: IStockState) => {
        switch (stockState) {
            case IStockState.UP:
                return <img src="../icons/stock-up.png" alt="arrow"/>
            case IStockState.DOWN:
                return <img src="../icons/stock-down.png" alt="arrow"/>
            case IStockState.NEUTRAL:
                return <img src="../icons/stock-neutral.png" alt="arrow"/>
        }
    }

    const setAllArrowsNeutral = () => {
        const newStock = stock;
        stock.forEach(s => {
            s.stockState = IStockState.NEUTRAL;
        });
        setStock(newStock);
    }

    const enableEdit = (event) => {
        if (!pageIsModified && getRoles().includes("ADMIN")) {
            event.stopPropagation()
            setPageIsModified(true)
            synchronize();
        }
    }

    const handleClick = (event, stockId) => {
        event.preventDefault();

        if (!pageIsModified)
            return;

        let stockItem: IStockItem = stock.filter(s => s.id === stockId)[0];

        switch (event.type) {
            case 'click':
                stockItem.stockPrice.current = Math.min(stockItem.stockPrice.current + stockInterval.increment, stockItem.stockPrice.max);
                if (stockItem.stockPrice.current < stockItem.stockPrice.min) stockItem.stockPrice.current = stockItem.stockPrice.min;
                break;
            case 'contextmenu':
                stockItem.stockPrice.current = Math.max(stockItem.stockPrice.current - stockInterval.increment, stockItem.stockPrice.min);
                if (stockItem.stockPrice.current > stockItem.stockPrice.max) stockItem.stockPrice.current = stockItem.stockPrice.max;
                break;
            default:
                console.warn("unsupported click.")
        }
        stockItem.updated = true;

        if (stockItem.stockPrice.current === stockItem.stockPrice.prevState) {
            stockItem.stockState = IStockState.NEUTRAL;
            stockItem.updated = false;
        } else if (stockItem.stockPrice.current > stockItem.stockPrice.prevState) {
            stockItem.stockState = IStockState.UP;
        } else if (stockItem.stockPrice.current < stockItem.stockPrice.prevState) {
            stockItem.stockState = IStockState.DOWN;
        }

        setStock([...stock]);
    };

    return (
        <div className="pageHeader order" onClick={e => enableEdit(e)} onContextMenu={e => enableEdit(e)}>
            <meta name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>

            {/* <div className={"stock-bar"}/>
           //https://www.npmjs.com/package/react-ticker
           <Ticker>
                {({index}) => (
                    <>
                        <h1 style={{paddingRight: "1rem", fontSize: "3rem"}}>
                            This is the Headline of element #{index}!
                        </h1>
                    </>
                )}
            </Ticker>*/}
            <div className={"bar-gold"}/>

            <div className={"stock-grid"}>
                {stock.filter(s => s.dish.itemState === "ACTIVE").sort((i1, i2) => i1.dish.positionOrder - i2.dish.positionOrder).map((stock, i) => {
                    return (
                        <div key={i} className={"stock-child " + (stock.updated ? "stock-active" : "")} onClick={e => handleClick(e, stock.id)} onContextMenu={e => handleClick(e, stock.id)}>
                            <div className={"stock-child-left"}>
                                <div className={"stock-child-arrow"}>
                                    {getStockArrow(stock.stockState)}
                                </div>
                            </div>
                            <div className={"stock-child-right"}>
                                {pageIsModified
                                    ? <div className={"stock-child-price"}>
                                        <div>
                                            {getDishData.filter(d => d.dishId === stock.dish.dishId).length > 0 ? getDishData.filter(d => d.dishId === stock.dish.dishId)[0].totalSold : 1}
                                        </div>
                                        {(stock.stockPrice.current / 100).toFixed(2)}</div>
                                    : <div className={"stock-child-price"}>{(stock.stockPrice.current / 100).toFixed(2)}</div>
                                }
                                <div className={"stock-child-bar"}/>
                                <div className={"stock-child-title"}>{stock.dish.name}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {pageIsModified
                ? <div className={"stock-apply"}>
                    <HighlightedButton text={"Cancel"} onClick={() => getStockData()} key={0}/>
                    <HighlightedButton text={"Apply"} onClick={() => updateStock()} key={1}/>
                </div>
                : null
            }
            <LoadIndicatorBar time={stockInterval.intervalValue} countdown={countDown}/>
        </div>
    );
}


export default OrderPage;
