import "../../css/Transaction.css";
import React, {useEffect, useRef, useState} from "react";
import {ITransaction} from "../Types";
import {IPagination, useAPI} from "../../utilities/useAPI";
import Pagination from "react-pagination-js";
import Transaction from "../common/Transaction";
import "react-pagination-js/dist/styles.css";

const TransactionsAdmin = () => {
    const {state, doFetch} = useAPI();
    const currentPage = useRef<number>(0);

    const [transactions, setTransaction] = useState<IPagination<ITransaction>>({
        content: [],
        empty: false,
        first: false,
        last: false,
        number: 0,
        numberOfElements: 0,
        pageable: {
            offset: 0,
            pageNumber: 0,
            pageSize: 1,
            paged: false,
            sort: {empty: false, sorted: false, unsorted: false},
            unpaged: false
        },
        size: 0,
        sort: {empty: false, sorted: false, unsorted: false},
        totalElements: 0,
        totalPages: 0
    });

    useEffect(() => {
        getTransactions()

        return () => {
            if (state.isLoading) {
                state.controller.abort();
            }
        };
        // eslint-disable-next-line
    }, []);

    const getTransactions = () => {
        doFetch<IPagination<ITransaction>>("/api/transaction/all/admin?page=" + currentPage.current + "&pageSize=" + 25).then(res => {
            currentPage.current = (res.pageable.pageNumber)
            setTransaction(res);
        });
    }
    const handleOrderClick = () => {
        return;
    }

    const changeCurrentPage = (numPage) => {
        numPage--;  // to match server starting at 0
        if (numPage < 0) {
            numPage = 0;
        }

        currentPage.current = (numPage);
        let oldTransactions = transactions;
        oldTransactions.pageable.pageNumber = numPage;
        oldTransactions.number = numPage;
        setTransaction(oldTransactions)
        getTransactions();
    };

    return (
        <div>
            <div className={"order-row"}>
                {transactions.content.map((t, index) => {
                    return <Transaction key={index} clickEvent={handleOrderClick}
                                        index={index} transaction={t} showOwner={true} showDate={true}/>
                })}
            </div>
            {transactions.totalElements > 0 && transactions.totalPages > 1
                ? <Pagination
                    currentPage={currentPage.current + 1}
                    totalSize={transactions.totalElements}
                    sizePerPage={transactions.pageable.pageSize}
                    changeCurrentPage={changeCurrentPage}
                    theme="border-bottom"
                />
                : null}
        </div>
    )
};

export default TransactionsAdmin;
