import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "css/Index.css";
import 'react-notifications/lib/notifications.css';
import {ThemeProvider} from "styled-components";
import Navbar from "components/navigation/Navbar";
import LoginPage from "components/pages/LoginPage";
import {useAPI} from "utilities/useAPI";
import {AccessTokenProvider, useAccessToken} from "utilities/AccessTokenProvider";
import OrderPage from "./components/pages/OrderPage";
import ResetPasswordModal from "./components/common/ResetPasswordModal";
import {NotificationContainer} from 'react-notifications';
import TransactionPage from "./components/pages/TransactionPage";
import AdminPage from "./components/pages/AdminPage";
import SettingsPage from "./components/pages/SettingsPage";
import StockPage from "./components/pages/StockPage";

const rootEl = document.getElementById("root");

const theme = {
    colors: {
        primary: "#001A27",
        secondary: "#FFCF06",
        primaryLight: "#69D2E7",
        secondaryLight: "#F6E6A2",
        grey: "#F1F1F1",
        red: "#F44336",
    }, colors2: {
        primary: "#001A27",
        secondary: "#FFCF06",
        primaryLight: "#007470",
        secondaryLight: "#F6E6A2",
        dark: "#261447",
        grey: "#F1F1F1",
        red: "#F44336",
    },
    fonts: {
        primary: "'Open Sans', sans-serif",
        secondary: "'Source Sans Pro', sans-serif",
        style: "'Fira Sans', sans-serif",
    },
    breakpoints: {
        md: 1024,
        xs: 480,
    },
    columnSize: 200,
} as const;


const App = () => {
    const [accessToken, setAccessToken] = useAccessToken();
    const [resetPassword, setResetPassword] = useState(false);
    const {refreshAccessToken, logOut} = useAPI();

    const onLoginSuccess = (response: any) => {
        setAccessToken(response.token);
        setResetPassword(response.shouldResetPassword);
        setTimeout(() => refreshAccessToken(), response.tokenLifetime * (1000 * 60) - 15000);
    };

    const onLogout = () => {
        logOut();
    };

    useEffect(() => {
        if (!accessToken){
            refreshAccessToken();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {!resetPassword ? null :
                <ResetPasswordModal resetPassword={setResetPassword}/>
            }
            {!accessToken || accessToken.length === 0 ? (
                <LoginPage onLoginSuccess={onLoginSuccess}/>
            ) : (
                <Router>
                    <div>
                        <Route
                            render={({location}) => (["/mirror"].includes(location.pathname) ? null :
                                <Navbar onLogout={onLogout}/>)}
                        /> <Switch>
                        <div className="appContent">
                            <Route exact path="/" component={OrderPage}/>
                            <Route exact path="/transactions" component={TransactionPage}/>
                            <Route exact path="/settings" component={SettingsPage}/>
                            <Route exact path="/stock" component={StockPage}/>
                            <Route exact path="/admin" component={AdminPage}/>
                        </div>
                    </Switch>
                    </div>
                </Router>
            )}
            <NotificationContainer/>
        </ThemeProvider>
    );
};

if (!rootEl) {
    console.warn('element with id: "root" not found!');
} else {
    ReactDOM.render(
        <AccessTokenProvider>
            <App/>
        </AccessTokenProvider>,
        rootEl
    );
}

export default App;