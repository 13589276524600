import React from "react";

const Transaction = ({clickEvent, index, transaction, showOwner, showDate}) => {
    return (
        <div className="column-order">
            <div className={"card-order"}
                 onClick={(e) => clickEvent(e, index)}>
                <div className={"order-top"}>
                    <div>{new Date(transaction.timestamp).toLocaleTimeString()}</div>
                    <div>{transaction.wallet.user.username}</div>
                    <div>{transaction.transactionType} {transaction.value !== 0 ? (transaction.value / 100).toFixed(2) : null}</div>
                </div>
                {transaction.order
                    ? <div className={"order-middle"}>
                        {transaction.order.items.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div>{item.quantity} x {item.name}</div>
                                </div>)
                        })}
                    </div>
                    : null}
                <div className={"order-bottom"}>
                    <div>Uitgevoerd door {transaction.executor}</div>
                    <div>{showDate && new Date(transaction.timestamp).toLocaleDateString() !== new Date().toLocaleDateString() ? new Date(transaction.timestamp).toLocaleDateString() : null} </div>
                </div>
            </div>
        </div>
    );
};

export default Transaction;
