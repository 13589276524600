import React, {useState} from "react";
import "../../css/LoginPage.css";
import Login from "../navigation/Login";

type tLoginPageProps = {
    onLoginSuccess: (response: any) => void;
};

const LoginPage = ({onLoginSuccess}: tLoginPageProps) => {
    const [loginError, setLoginError] = useState(false);
    const redirectHome = (e) => {
        window.location.href = "https://www.foodterminal.be";
    }

    //TODO: Aanpassen om useAPI te gebruiken.
    const handleLogin = async (username: string, password: string) => {
        setLoginError(false);
        let credentials = {username: username, password: password};
        fetch("/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    console.log(res);
                    throw new Error("Response is not OK.");
                }
            })
            .then((response) => {
                if (response) onLoginSuccess(response);
            })
            .catch((error) => {
                console.error(error);
                // document!.getElementById("login-button")!.blur();
                // Kleine delay voor UX: als er al een error message staat, toont dit aan de user dat zijn volgende poging daadwerkelijk is doorgegaan.
                if (loginError) {
                    setTimeout(() => setLoginError(true), 250);
                } else {
                    setLoginError(true);
                }
            });
    };

    return <div>
        <div className="return" onClick={redirectHome}>
            <div className="arrow"/>
            <div className="return-text">Return to website</div>
            <div className="return-line"/>
        </div>
        <Login logIn={handleLogin} error={loginError}/>
    </div>
};

export default LoginPage;
