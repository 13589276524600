import React from "react";
import {Alert, Color} from "@material-ui/lab";
import styled from "styled-components";

type tCustomAlertProps = {
    message: string;
    type: string;
    small?: boolean;
};

type tStyledAlertProps = {
    small?: boolean;
};

const StyledAlert = styled(Alert)<tStyledAlertProps>`
  margin: auto;
  justify-content: center;

  && {
    color: ${({severity}) => (severity === "error" ? ({theme}) => theme.colors.red : "#4caf50")};
    background-color: transparent;
  }

  .MuiAlert-icon {
    .MuiSvgIcon-root {
      color: ${({severity}) => (severity === "error" ? ({theme}) => theme.colors.red : "#4caf50")};
      &:hover {
        color: inherit;
      }
    }

    font-size: ${({small}) => (small ? "1.6rem" : "2rem")};
    align-items: center;
  }

  .MuiAlert-message {
    font-size: ${({small}) => (small ? "1rem" : "1.6rem")};
    font-family: ${({theme}) => theme.fonts.primary};
    font-weight: 700;
    line-height: 2;
  }
`;

const CustomAlert = ({message, type, small}: tCustomAlertProps) => {
    return (
        <StyledAlert variant="filled" severity={type as Color} small={small}>
            {message}
        </StyledAlert>
    );
};

export default CustomAlert;
