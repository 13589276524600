import React, {useEffect, useState} from "react";
import {useAPI} from "../../utilities/useAPI";
import {IDish, IDishType} from "../Types";
import AdminDish from "./AdminDish";
import {NotificationManager} from 'react-notifications';

const MenuItemsAdmin = () => {

    const [dishes, setDishes] = useState<IDish[]>([]);
    const [types, setTypes] = useState<IDishType[]>([]);
    const [openDish, setOpenDish] = useState<number>(-1);
    const {doFetch} = useAPI();

    useEffect(() => {
        doFetch<IDishType[]>("/api/dish/types").then(res => setTypes(res));
        doFetch<IDish[]>("/api/dish/all").then((res) => {
            setDishes(res);
        });
        // eslint-disable-next-line
    }, []);

    const handleUp = (isUp, i) => {
        setOpenDish(-1);
        isUp = isUp[0];
        if ((i === 0 && isUp) || (i === dishes.length - 1 && !isUp)) {
            return;
        }
        doFetch<IDish[]>("/api/dish/swap", "POST", JSON.stringify([dishes[i], dishes[isUp ? i - 1 : i + 1]]), {
            "Content-Type": "application/json",
        }).then((res) => {
            setDishes(res);
        });
    }

    const handleDelete = (i) => {
        setOpenDish(-1);
        NotificationManager.warning("Ben je zeker dat je " + dishes[i].name + " wilt verwijderen?", 'Klik hier.', 5000, () => {
            dishes[i].itemState = "DELETED";
            doFetch<IDish[]>("/api/dish/delete", "DELETE", JSON.stringify(dishes[i]), {
                "Content-Type": "application/json",
            }).then((res) => setDishes(res));
        });
    }

    const handleVisible = (i) => {
        setOpenDish(-1);
        dishes[i].itemState = dishes[i].itemState === "ACTIVE" ? "HIDDEN" : "ACTIVE"
        doFetch<IDish>("/api/dish/update", "POST", JSON.stringify(dishes[i]), {
            "Content-Type": "application/json",
        }).then(() => setDishes(dishes));
    }

    const handleAdd = () => {
        setOpenDish(-1);
        doFetch<IDish[]>("/api/dish/add").then((res) => setDishes(res));
    }

    return (
        <div className={"admin-child"}>
            {dishes.map((dish, i) =>
                <AdminDish types={types} key={i} dish={dish} setUp={b => handleUp(b, i)} deleteDish={() => handleDelete(i)} setVisible={() => handleVisible(i)}
                           showOptions={i === openDish} setShowOptions={b => setOpenDish(b ? i : -1)}/>)}
            <div className={"admin-dish"} onClick={handleAdd}>
                <div className={"add-dish-button"}>+</div>
            </div>
        </div>
    );
}

export default MenuItemsAdmin;