import {useState} from "react";
import {useAccessToken} from "./AccessTokenProvider";
import {NotificationManager} from 'react-notifications';

export interface State {
    isLoading: boolean;
    shouldShowLoading: boolean;
    error: boolean;
    controller: AbortController;
}

export interface IPagination<T> {
    content: T[]
    empty: false,
    first: false,
    last: false,
    number: number,
    numberOfElements: number,
    pageable: {
        offset: number,
        pageNumber: number,
        pageSize: number,
        paged: false,
        sort: { empty: false, sorted: false, unsorted: false },
        unpaged: false
    },
    size: number,
    sort: { empty: false, sorted: false, unsorted: false },
    totalElements: number,
    totalPages: number
}

export const useAPI = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [shouldShowLoading, setShouldShowLoading] = useState(false);
    const [accessToken, setAccessToken] = useAccessToken();
    // Controller die requests kan stoppen (wanneer de vragende component unmount voor de request gedaan is, om memory leaks tegen te gaan)
    const controller = new AbortController();
    let openRequests = 0;

    async function doFetch<T>(inputUrl: string, inputMethod?: string, inputBody?, inputHeaders?): Promise<T> {
        return new Promise(async (resolve, reject) => {
            let loaderStartTime;
            setIsLoading(true);
            // Bij heel korte laadtijden geen loader tonen voor betere UX.
            let loaderDelay = setTimeout(() => {
                // Timer starten om de gebruiker bij het tonen van een loader voor een minimumtijd de loader te tonen, beetje om dezelfde reden dat we die eerst niet tonen.
                loaderStartTime = Date.now();
                setShouldShowLoading(true);
            }, 500);
            if (openRequests === 0) setError(false);
            openRequests++;

            let options = {
                signal: controller.signal,
                headers: {},
            };

            if (inputHeaders) options.headers = Object.assign(options.headers, {...inputHeaders});

            if (accessToken) {
                options.headers = Object.assign(options.headers, {Authorization: `Bearer ${accessToken}`});
            }

            options = Object.assign(options, {method: inputMethod ? inputMethod : "GET"});
            if (inputBody) options = Object.assign(options, {body: inputBody});

            let response;
            try {
                response = await fetch(inputUrl, options);
                const data = await handleFetchResponse(response);
                clearTimeout(loaderDelay);
                endRequest(loaderStartTime);
                resolve(data);
            } catch (error) {
                if (inputUrl !== "/api/refreshtoken")
                    NotificationManager.error(response.status + " " + inputUrl, 'Er ging iets mis');
                console.error(error);
                setError(true);
                clearTimeout(loaderDelay);
                endRequest(loaderStartTime);
                reject();
            }
        });
    }

    const endRequest = (loaderStartTime) => {
        openRequests--;
        if (openRequests === 0) {
            let timePassedSinceLoader: number = Date.now() - loaderStartTime;
            setIsLoading(false);
            if (timePassedSinceLoader >= 250 || loaderStartTime === undefined) {
                setShouldShowLoading(false);
            } else {
                setTimeout(() => {
                    setShouldShowLoading(false);
                }, 250 - timePassedSinceLoader);
            }
        }
    };

    const logOut = () => {
        setAccessToken("");
        doFetch("/api/logout", "POST").then(() => console.debug("Logged in"));
    };

    const handleFetchResponse = async (res) => {
        if (!res.ok) {
            throw new Error(res.status);
        }

        if (res.headers.get("content-type") === null) return null;
        return res.ok ? (res.json ? await res.json() : res.text ? await res.text() : {}) : null;
    };

    const state: State = {
        isLoading: isLoading,
        shouldShowLoading: shouldShowLoading,
        error: error,
        controller: controller,
    };

    const refreshAccessToken = () => {
        return new Promise(async (resolve, reject) => {
            doFetch("/api/refreshtoken", "POST")
                .then((res: any) => {
                    setAccessToken(res.accessToken);
                    setTimeout(() => {
                        refreshAccessToken();
                    }, res.lifetime * (1000 * 60) - 15000);
                    resolve(true);
                })
                .catch((error) => {
                    if (error.status !== "406") {
                        NotificationManager.warning("Sessie vervallen, gelieve opnieuw in te loggen.");
                    }
                    logOut();
                    reject();
                });
        });
    };

    return {state, doFetch, refreshAccessToken, logOut};
};
